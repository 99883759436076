@if(loading$ | async) {
<div
  class="loading-container d-flex flex-column align-items-center justify-content-center"
>
  <div class="spinner-border text-primary" role="status">
    <span class="visually-hidden">{{ 'spinner.loading' | translate }}</span>
  </div>
  <div class="pt-3 fs-5">
    {{ 'spinner.contentLoading' | translate }}
  </div>
</div>
}
