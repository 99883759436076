import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MsalModule } from '@azure/msal-angular';
import { NavbarComponent } from './homepage/navbar/navbar.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { SpinnerComponent } from './core/spinner/spinner.component';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MsalModule,
    NavbarComponent,
    FormsModule,
    TranslateModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    SpinnerComponent,
  ],
  selector: 'rtpca-root',
  template: `
    <rtpca-spinner></rtpca-spinner>
    <rtpca-navbar></rtpca-navbar>
    <div class="container pt-4" style="height: calc(100% - 200px)">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class AppComponent {}
